import React, { useState, useEffect } from 'react';

// Sample blog data (this would typically come from an API)
const initialBlogs = [
  { id: 1, title: 'Understanding React', category: 'Development', excerpt: 'A deep dive into React fundamentals.', author: 'John Doe', date: '2024-09-29', tags: ['react', 'javascript'] },
  { id: 2, title: 'Getting Started with Tailwind CSS', category: 'Design', excerpt: 'Learn how to build beautiful UIs.', author: 'Jane Smith', date: '2024-09-30', tags: ['tailwind', 'css'] },
  { id: 3, title: 'JavaScript Basics', category: 'Development', excerpt: 'A guide to understanding JavaScript.', author: 'John Doe', date: '2024-09-28', tags: ['javascript', 'programming'] },
  { id: 4, title: 'Advanced CSS Techniques', category: 'Design', excerpt: 'Take your CSS skills to the next level.', author: 'Alice Johnson', date: '2024-09-27', tags: ['css', 'design'] },
  { id: 5, title: 'The Future of AI', category: 'Technology', excerpt: 'What lies ahead in AI technologies.', author: 'Bob Brown', date: '2024-09-26', tags: ['ai', 'technology'] },
  { id: 6, title: 'Building REST APIs with Node.js', category: 'Development', excerpt: 'Creating robust APIs with Node.js.', author: 'John Doe', date: '2024-09-25', tags: ['nodejs', 'api'] },
  { id: 7, title: 'UI/UX Design Principles', category: 'Design', excerpt: 'Fundamental principles for effective UI/UX.', author: 'Alice Johnson', date: '2024-09-24', tags: ['ui', 'ux'] },
  { id: 8, title: 'Exploring Blockchain Technology', category: 'Technology', excerpt: 'Understanding the basics of blockchain.', author: 'Bob Brown', date: '2024-09-23', tags: ['blockchain', 'technology'] },
  { id: 9, title: 'Understanding Redux', category: 'Development', excerpt: 'State management in React applications.', author: 'John Doe', date: '2024-09-22', tags: ['redux', 'javascript'] },
  { id: 10, title: 'The Basics of GraphQL', category: 'Development', excerpt: 'Querying APIs with GraphQL.', author: 'Jane Smith', date: '2024-09-21', tags: ['graphql', 'api'] },
];

// Categories available
const categories = ['Development', 'Design', 'Technology'];

// All possible tags for filtering
const allTags = ['react', 'javascript', 'tailwind', 'css', 'programming', 'ai', 'technology', 'nodejs', 'api', 'ui', 'ux', 'blockchain', 'redux', 'graphql'];

const ExplorePage = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [likes, setLikes] = useState(new Array(initialBlogs.length).fill(0)); // To track likes for each blog
  const [filteredBlogs, setFilteredBlogs] = useState(initialBlogs);
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(5); // Number of blogs per page
  const [selectedTags, setSelectedTags] = useState([]); // Tags for filtering
  const [user, setUser] = useState(null); // Simulated user authentication

  // Effect to filter blogs based on category, search query, and tags
  useEffect(() => {
    let blogs = initialBlogs;

    if (selectedCategory) {
      blogs = blogs.filter(blog => blog.category === selectedCategory);
    }

    if (searchQuery) {
      blogs = blogs.filter(blog => blog.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    if (selectedTags.length > 0) {
      blogs = blogs.filter(blog => selectedTags.some(tag => blog.tags.includes(tag)));
    }

    // Update filtered blogs
    setFilteredBlogs(blogs);
  }, [selectedCategory, searchQuery, selectedTags]);

  // Paginate the blogs
  const indexOfLastBlog = page * postsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - postsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const handleLike = (index) => {
    const newLikes = [...likes];
    newLikes[index] += 1; // Increment likes
    setLikes(newLikes);
  };

  const handlePageChange = (direction) => {
    if (direction === 'next' && indexOfLastBlog < filteredBlogs.length) {
      setPage(page + 1);
    } else if (direction === 'prev' && page > 1) {
      setPage(page - 1);
    }
  };

  // Simulated user login/logout
  const handleLogin = () => {
    setUser({ name: 'John Doe' }); // Simulate a logged-in user
  };

  const handleLogout = () => {
    setUser(null); // Simulate logout
  };

  // Handle tag selection
  const toggleTag = (tag) => {
    setSelectedTags(prevTags =>
      prevTags.includes(tag) ? prevTags.filter(t => t !== tag) : [...prevTags, tag]
    );
  };

  return (
    <div className="min-h-screen p-5">
      <h1 className="text-3xl font-bold mb-4">Explore Blogs</h1>

      {/* User Authentication */}
      <div className="mb-4">
        {user ? (
          <div>
            <span className="mr-2">Welcome, {user.name}!</span>
            <button onClick={handleLogout} className="text-red-500">Logout</button>
          </div>
        ) : (
          <button onClick={handleLogin} className="bg-blue-500 text-white rounded px-4 py-2">Login</button>
        )}
      </div>

      {/* Search Bar for Blog Titles */}
      <input
        type="text"
        placeholder="Search Blogs..."
        className="border border-gray-300 rounded p-2 mb-4 w-full"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {/* Category Selection */}
      <div className="mb-4">
        <h2 className="text-lg font-semibold">Select a Category:</h2>
        <ul className="list-disc pl-5">
          {categories.map((category) => (
            <li
              key={category}
              className={`cursor-pointer hover:text-blue-600 ${
                selectedCategory === category ? 'font-bold' : ''
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </li>
          ))}
        </ul>
      </div>

      {/* Tag Filtering */}
      <div className="mb-4">
        <h2 className="text-lg font-semibold">Select Tags:</h2>
        <div className="flex flex-wrap">
          {allTags.map((tag) => (
            <span
              key={tag}
              onClick={() => toggleTag(tag)}
              className={`cursor-pointer border rounded-full px-3 py-1 m-1 transition ${
                selectedTags.includes(tag) ? 'bg-blue-500 text-white' : 'border-gray-300'
              }`}
            >
              {tag}
            </span>
          ))}
        </div>
      </div>

      {/* Display Blogs based on Selected Category */}
      {selectedCategory && (
        <div>
          <h2 className="text-lg font-semibold mb-2">
            Blogs in "{selectedCategory}" Category:
          </h2>
          <ul className="list-disc pl-5">
            {currentBlogs.length > 0 ? (
              currentBlogs.map((blog, index) => (
                <li key={blog.id} className="mb-2">
                  <div className="border border-gray-200 p-3 rounded-md shadow-md">
                    <h3 className="font-semibold">{blog.title}</h3>
                    <p className="text-gray-600">{blog.excerpt}</p>
                    <p className="text-sm text-gray-500">By {blog.author} on {blog.date}</p>
                    <div className="mt-2 flex justify-between items-center">
                      <button
                        className="text-blue-500 hover:underline"
                        onClick={() => handleLike(index)}
                      >
                        👍 {likes[index]} Likes
                      </button>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <li>No blogs available in this category.</li>
            )}
          </ul>

          {/* Pagination */}
          <div className="flex justify-between mt-4">
            <button
              className="bg-gray-300 px-4 py-2 rounded disabled:opacity-50"
              onClick={() => handlePageChange('prev')}
              disabled={page === 1}
            >
              Previous
            </button>
            <span>Page {page}</span>
            <button
              className="bg-gray-300 px-4 py-2 rounded disabled:opacity-50"
              onClick={() => handlePageChange('next')}
              disabled={indexOfLastBlog >= filteredBlogs.length}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExplorePage;
