import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const SettingsPage = () => {
  // State variables for user settings
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  // Handlers for form inputs
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') setPassword(value);
    else if (name === 'newPassword') setNewPassword(value);
    else if (name === 'confirmPassword') setConfirmPassword(value);
  };

  const saveSettings = () => {
    // Add logic to save settings (e.g., API call)
    console.log('Settings saved:', { username, email, profilePicture, newPassword });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100 p-5">
      {/* Header Section with Placeholder for Logo */}
      <div
      onClick={() => navigate("/")}
      className="cursor-pointer" // Change cursor to pointer for better UX
      role="button" // Adds accessibility
      tabIndex={0} // Makes it focusable
    >
      <img
        src="https://img.logoipsum.com/250.svg"
        alt="logo home"
        className="w-64 h-auto" // Add Tailwind classes for width and height
      />
      </div>

      {/* Settings Form */}
      <div className="bg-white p-5 rounded-md shadow-md">
        {/* Profile Picture Upload */}
        <div className="mb-4">
          <label className="block mb-1" htmlFor="profilePicture">Profile Picture:</label>
          <input
            type="file"
            id="profilePicture"
            accept="image/*"
            onChange={handleProfilePictureChange}
            className="border border-gray-300 rounded-md p-2 w-full"
          />
          {profilePicture && (
            <img src={profilePicture} alt="Profile Preview" className="mt-2 h-20 w-20 rounded-full" />
          )}
        </div>

        {/* Username */}
        <div className="mb-4">
          <label className="block mb-1" htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="border border-gray-300 rounded-md p-2 w-full"
            placeholder="Enter your username"
          />
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="block mb-1" htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border border-gray-300 rounded-md p-2 w-full"
            placeholder="Enter your email"
          />
        </div>

        {/* Change Password Section */}
        <h2 className="text-xl font-semibold mt-6 mb-4">Change Password</h2>
        <div className="mb-4">
          <label className="block mb-1" htmlFor="password">Current Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            className="border border-gray-300 rounded-md p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1" htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            value={newPassword}
            onChange={handlePasswordChange}
            className="border border-gray-300 rounded-md p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1" htmlFor="confirmPassword">Confirm New Password:</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handlePasswordChange}
            className="border border-gray-300 rounded-md p-2 w-full"
          />
        </div>

        {/* Save Settings Button */}
        <button
          onClick={saveSettings}
          className="mt-5 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
        >
          Save Settings
        </button>
      </div>
    </div>
  );
};

export default SettingsPage;
