import React, { useEffect, useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Divider,
  Link,
} from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import GoogleLoginButton from '../components/GoogleLoginButton';
import FbLoginButton from '../components/FbLoginButton';

const theme = createTheme({
  palette: {
    background: {
      default: '#000',  // This will be the background color
    },
    text: {
      primary: '#fff',  // Ensure text is white
    },
  },
  typography: {
    fontFamily: '"Orbitron", sans-serif',
    h1: {
      fontFamily: '"Rajdhani", sans-serif',
    },
  },
});

const MotionBox = motion.create(Box);

const Footer = () => (
  <Box
    component="footer"
    sx={{
      mt: 2,
      py: 2,
      textAlign: 'center',
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }}
  >
    <Typography variant="body2" color="inherit">
      &copy; {new Date().getFullYear()} Blenve. All Rights Reserved.{' '}
      <Link href="/privacy-policy.html" target="_blank" rel="noopener" color="inherit" underline="always">
        Privacy Policy
      </Link>
    </Typography>
  </Box>
);

const ParticleEffect = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const particles = [];
    const mousePosition = { x: null, y: null };

    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 3 + 1;
        this.speedX = Math.random() * 3 - 1.5;
        this.speedY = Math.random() * 3 - 1.5;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.size > 0.2) this.size -= 0.1;
      }

      draw() {
        ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.lineWidth = 2;

        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();

        const dx = mousePosition.x - this.x;
        const dy = mousePosition.y - this.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < 100) {
          ctx.beginPath();
          ctx.moveTo(this.x, this.y);
          ctx.lineTo(mousePosition.x, mousePosition.y);
          ctx.stroke();
        }
      }
    }

    function createParticles() {
      for (let i = 0; i < 500; i++) {
        particles.push(new Particle());
      }
    }

    function animateParticles() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < particles.length; i++) {
        particles[i].update();
        particles[i].draw();
      }
      animationFrameId = requestAnimationFrame(animateParticles);
    }

    createParticles();
    animateParticles();

    const handleMouseMove = (event) => {
      mousePosition.x = event.x;
      mousePosition.y = event.y;
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0 }} />;
};

const LoginPage = () => {
  const formAnimation = useAnimation();

  useEffect(() => {
    formAnimation.start({
      y: [50, 0],
      opacity: [0, 1],
      transition: { duration: 1, ease: 'easeOut' },
    });
  }, [formAnimation]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          position: 'relative',
          minHeight: '100vh',
          backgroundColor: 'background.default', // Use theme's background color
        }}
      >
        <ParticleEffect />
        <Grid container style={{ minHeight: '100vh' }}>
          <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <MotionBox
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              style={{
                background: 'rgba(0, 0, 0, 0.5)',
                padding: '20px',
                borderRadius: '15px',
                textAlign: 'center',
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  background: 'linear-gradient(45deg, #ff00cc, #3333ff)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Blenve
              </Typography>
              <Typography variant="h6" color="white" style={{ marginTop: '10px' }}>
                Connect. Create. Share.
              </Typography>
            </MotionBox>
          </Grid>
          <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <MotionBox
              animate={formAnimation}
              sx={{
                background: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(20px)',
                borderRadius: '20px',
                padding: '20px',
                boxShadow: '0 0 20px rgba(0, 255, 255, 0.3)',
                width: '90%', // Responsive width
                maxWidth: '70%', // Set a maximum width
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    label="Email"
                    InputProps={{
                      style: { color: 'white', background: 'rgba(255, 255, 255, 0.1)' },
                    }}
                    InputLabelProps={{ style: { color: 'white' } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="password"
                    label="Password"
                    InputProps={{
                      style: { color: 'white', background: 'rgba(255, 255, 255, 0.1)' },
                    }}
                    InputLabelProps={{ style: { color: 'white' } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    style={{
                      background: 'linear-gradient(45deg, #ff00cc, #3333ff)',
                      color: 'white',
                      padding: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    Sign in
                  </Button>
                  <Divider sx={{ color: 'white', my: 2 }}>or sign up with</Divider>
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={2}>
                    <GoogleLoginButton />
                    <FbLoginButton />
                  </Box>
                </Grid>
              </Grid>
            </MotionBox>
          </Grid>
        </Grid>
        <Footer/>
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
