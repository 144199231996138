import React from 'react';
import { Box, List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Divider } from '@mui/material';

const users = [
    { id: 1, name: 'Alice', online: true, avatar: 'https://randomuser.me/api/portraits/women/1.jpg', lastMessage: '2024-10-11T14:30:00Z', role: 'UI Designer' },
    { id: 2, name: 'Bob', online: false, avatar: 'https://randomuser.me/api/portraits/men/1.jpg', lastMessage: '2024-10-10T10:15:00Z', role: 'Engineer' },
    { id: 3, name: 'Charlie', online: true, avatar: 'https://randomuser.me/api/portraits/men/2.jpg', lastMessage: '2024-10-09T12:00:00Z', role: 'YouTuber' },
    { id: 4, name: 'David', online: false, avatar: 'https://randomuser.me/api/portraits/men/3.jpg', lastMessage: '2024-10-09T09:45:00Z', role: 'Developer' },
    { id: 5, name: 'Eve', online: true, avatar: 'https://randomuser.me/api/portraits/women/2.jpg', lastMessage: '2024-10-08T14:20:00Z', role: 'Designer' },
    { id: 6, name: 'Frank', online: false, avatar: 'https://randomuser.me/api/portraits/men/4.jpg', lastMessage: '2024-10-07T16:30:00Z', role: 'Writer' },
    { id: 7, name: 'Grace', online: true, avatar: 'https://randomuser.me/api/portraits/women/3.jpg', lastMessage: '2024-10-06T11:50:00Z', role: 'Artist' },
    { id: 8, name: 'Hannah', online: false, avatar: 'https://randomuser.me/api/portraits/women/4.jpg', lastMessage: '2024-10-05T08:00:00Z', role: 'Photographer' },
    { id: 9, name: 'Isaac', online: true, avatar: 'https://randomuser.me/api/portraits/men/5.jpg', lastMessage: '2024-10-04T09:30:00Z', role: 'Musician' },
    { id: 10, name: 'Jack', online: false, avatar: 'https://randomuser.me/api/portraits/men/6.jpg', lastMessage: '2024-10-03T07:15:00Z', role: 'Developer' },
    { id: 11, name: 'Kathy', online: true, avatar: 'https://randomuser.me/api/portraits/women/5.jpg', lastMessage: '2024-10-02T10:45:00Z', role: 'Editor' },
    { id: 12, name: 'Leo', online: false, avatar: 'https://randomuser.me/api/portraits/men/7.jpg', lastMessage: '2024-10-01T14:30:00Z', role: 'Scientist' },
    { id: 13, name: 'Mia', online: true, avatar: 'https://randomuser.me/api/portraits/women/6.jpg', lastMessage: '2024-09-30T12:20:00Z', role: 'Teacher' },
    { id: 14, name: 'Noah', online: false, avatar: 'https://randomuser.me/api/portraits/men/8.jpg', lastMessage: '2024-09-29T16:10:00Z', role: 'Consultant' },
    { id: 15, name: 'Olivia', online: true, avatar: 'https://randomuser.me/api/portraits/women/7.jpg', lastMessage: '2024-09-28T11:00:00Z', role: 'Engineer' },
    { id: 16, name: 'Paul', online: false, avatar: 'https://randomuser.me/api/portraits/men/9.jpg', lastMessage: '2024-09-27T09:30:00Z', role: 'Writer' },
    { id: 17, name: 'Quinn', online: true, avatar: 'https://randomuser.me/api/portraits/women/8.jpg', lastMessage: '2024-09-26T08:45:00Z', role: 'Marketing Specialist' },
    { id: 18, name: 'Riley', online: false, avatar: 'https://randomuser.me/api/portraits/women/9.jpg', lastMessage: '2024-09-25T13:30:00Z', role: 'Content Creator' },
    { id: 19, name: 'Sam', online: true, avatar: 'https://randomuser.me/api/portraits/men/10.jpg', lastMessage: '2024-09-24T10:30:00Z', role: 'Designer' },
    { id: 20, name: 'Tina', online: false, avatar: 'https://randomuser.me/api/portraits/women/10.jpg', lastMessage: '2024-09-23T15:20:00Z', role: 'Photographer' },
  ];
  

const ChatSidebar = () => { // Defaulting to empty arrays
    const activeUsers = users.filter((user) => user.online);
    const inactiveUsers = users.filter((user) => !user.online);
    const combinedUsers = [...activeUsers, ...inactiveUsers];
  // Function to format the last message time
  const formatLastMessageTime = (lastMessage) => {
    const date = new Date(lastMessage);
    const today = new Date();
    
    const isToday = date.toDateString() === today.toDateString();
    const isThisYear = date.getFullYear() === today.getFullYear();

    if (isToday) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (isThisYear) {
      return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
    } else {
      return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
    }
  };
    
  return (
    <Box
      sx={{
        width: '300px',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        p: 2,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
      }}
    >
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#000000' }}>
        Messaging
      </Typography>

      <List sx={{ flexGrow: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}>
        {combinedUsers.map((user, index) => (
          <React.Fragment key={user.id}>
            <ListItem
              sx={{
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingY: '0.1px', // Reduced vertical padding
              }}
            >
              <ListItemAvatar>
                <Avatar src={user.avatar} sx={{ border: user.online ? '2px solid #4caf50' : '2px solid #ccc' }} />
              </ListItemAvatar>
              <ListItemText 
                primary={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body1" sx={{ fontSize: '0.9rem' }}>
                      {user.name}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" sx={{ fontSize: '0.7rem' }}>
                      {formatLastMessageTime(user.lastMessage)}
                    </Typography>
                  </Box>
                } 
                secondary={
                  <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.7rem' }}>
                    {user.role}
                  </Typography>
                }
              />
            </ListItem>
            {index < combinedUsers.length - 1 && <Divider sx={{ my: 0.5 }} />} {/* Reduced margin for divider */}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default ChatSidebar;