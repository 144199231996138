import React from 'react';
import { GoogleLogin } from '@react-oauth/google'; // Ensure correct import
// import {jwtDecode} from 'jwt-decode'; // Import jwtDecode to handle token decoding
import { Box } from '@mui/material'; // Assuming you're using Material-UI for Box component
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const clientId = '203241175174-mh5c3q9o2mb57cs4il6408k5kd5e37c2.apps.googleusercontent.com'; // Replace with your actual client ID
const GoogleLoginButton = () => {
    // const [user, setUser] = useState(null);
    const navigate = useNavigate();
  
    const handleGoogleSuccess = (response) => {
      const token = response.credential; // Get the token from Google
      // console.log("Token received: ", token);
    
      // Send the token to the backend for validation
      axios
        .post('https://blenve.com/api/GoogleOAuth.php', { token: token })
        .then((response) => {
          if (response.data.status === 'success') {
            // console.log('User authenticated:', response.data);
            // window.location.href = '/HomePage'; // Redirect to homepage
            const userData = {
              name: response.data.name,
              email: response.data.email,
              profilePicture: response.data.profile_picture,
            };
            navigate('/HomePage', { state: userData });
          } else {
            // console.error('Authentication failed:', response.data.message);
          }
        })
        .catch((error) => {
          console.error('Error verifying token:', error);
        });
    };

    const handleGoogleFailure = () => {
      console.log('Google Login Failed');
    };
  
    return (
      <Box sx={{ width: '100%', height: '100%' }}>
        <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          logo_alignment="center"
          text='signup_with'
          onError={handleGoogleFailure}
          useOneTap // Enables One Tap login
          context="signin"
          style={{
            width: '100%', // Adjust as necessary for button width
          }}
        />
        </GoogleOAuthProvider>
      </Box>
    );
};

export default GoogleLoginButton;
