import React, { useState } from 'react';
import { BlockEditorProvider, BlockList, BlockTools, WritingFlow, ObserveTyping } from '@wordpress/block-editor';
import { SlotFillProvider } from '@wordpress/components';
import { serialize } from '@wordpress/blocks';
import { registerCoreBlocks } from '@wordpress/block-library';
import '@wordpress/components/build-style/style.css';
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-editor/build-style/style.css';
// import { useNavigate } from 'react-router-dom';
// import './App.css';
const yourMediaUploadFunction = async (files) => {
  const formData = new FormData();

  // Assuming you're uploading a single file
  formData.append('file', files[0]); // If multiple files, you can loop over the files array
  formData.append('title', 'Uploaded via React Gutenberg');

  try {
    const response = await fetch('/wp-json/wp/v2/media', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer YOUR_AUTH_TOKEN`, // Ensure you add appropriate authentication
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Media upload failed');
    }

    const data = await response.json();
    return [{ id: data.id, url: data.source_url }]; // Return the uploaded media details
  } catch (error) {
    console.error('Media upload error:', error);
    throw error;
  }
};
// Example editor settings
const editorSettings = {
  alignWide: true,
  allowedBlockTypes: [
    'core/paragraph',
    'core/image',
    'core/heading',
    'core/list',
    'core/quote',
    'core/gallery',
    'core/cover',
    'core/audio',
    'core/video',
    'core/file',
    'core/latest-posts',
    'core/latest-comments',
    'core/separator',
    'core/spacer',
    'core/button',
    'core/buttons',
    'core/table',
    'core/text-columns',
    'core/more',
    'core/embed',
    'core/custom-html',
    'core/html',
    'core/rss',
    'core/shortcode',
    'core/archives',
    'core/categories',
    'core/tag-cloud',
    'core/calendar',
    'core/page-list',
    'core/site-logo',
    'core/site-title',
    'core/site-tagline',
    'core/search',
    'core/social-links',
    'core/social-link',
    // Add any custom block types here
  ],

  /**
   * Autosave Settings
   */
  autosaveInterval: 15000, // Time in milliseconds between autosaves (e.g., 15 seconds).

  /**
   * Toolbar and UI Behavior
   */
  hasFixedToolbar: false, // Whether the toolbar is fixed to the top of the editor.
  focusMode: false, // Enables focus mode, which dims surrounding blocks.
  isRTL: false, // Set to true for right-to-left languages.
  isFullscreenMode: true, // Enable/disable fullscreen editing mode.
  hasCopyHandler: true, // Whether to use custom copy handlers for block copying/pasting.
  disableCustomColors: false, // Disable custom color palettes in blocks.
  disableCustomFontSizes: false, // Disable custom font size selection in blocks.

  /**
   * Block Templates
   * Pre-configure blocks and their attributes for new posts or pages.
   */
  templates: [
    ['core/paragraph', { placeholder: 'Write your introduction...' }],
    ['core/image', {}],
    ['core/heading', { placeholder: 'Add a section title' }],
  ],
  templateLock: false, // Set to 'all', 'insert', or false. Controls if blocks can be added, moved, or removed.
  
  /**
   * Media Upload Settings
   */
  mediaLibrary: true, // Enable or disable media library access.
  mediaUpload: async (files, onSuccess, onError) => {
    // Custom media upload handler
    try {
      const response = await yourMediaUploadFunction(files);
      onSuccess(response);
    } catch (error) {
      onError(error);
    }
  },

  /**
   * Post Locking and Status
   */
  isPostLockEnabled: true, // Enables post locking to prevent multiple editors at once.
  isSavingNonPostEntityChanges: true, // Handles non-post entities like reusable blocks.

  /**
   * Custom Block Categories
   */
  blockCategories: [
    { slug: 'common', title: 'Common Blocks', icon: 'wordpress' },
    { slug: 'formatting', title: 'Formatting', icon: 'text' },
    { slug: 'layout', title: 'Layout Elements', icon: 'layout' },
  ],

  /**
   * Font Sizes and Color Palettes
   */
  fontSizes: [
    { name: 'Small', slug: 'small', size: 12 },
    { name: 'Normal', slug: 'normal', size: 16 },
    { name: 'Large', slug: 'large', size: 24 },
    { name: 'Huge', slug: 'huge', size: 36 },
  ],
  colors: [
    { name: 'Red', slug: 'red', color: '#ff0000' },
    { name: 'Green', slug: 'green', color: '#00ff00' },
    { name: 'Blue', slug: 'blue', color: '#0000ff' },
  ],

  /**
   * Post Formats
   */
  availablePostFormats: ['standard', 'aside', 'image', 'gallery', 'video'], // Allow specific post formats.
  
  /**
   * Panels (Show/Hide Side Panels)
   */
  enableCustomFields: false, // Enables the custom fields meta box in the sidebar.
  enablePostPublishPanel: true, // Controls the visibility of the "Publish" panel.

  /**
   * Other Editor Settings
   */
  bodyPlaceholder: 'Write your story...', // Placeholder text for the body.
  titlePlaceholder: 'Enter title here', // Placeholder text for the post title.
  codeEditingEnabled: true, // Enables/disables code editing.
  maxWidth: 1080, // Set the maximum width for the editor content (in pixels).
  allowedMimeTypes: ['image/jpeg', 'image/png', 'video/mp4'], // Restrict allowed media MIME types.

  /**
   * Link Settings
   */
  openInNewTab: true, // Default setting for "Open in New Tab" in link options.
  linkSuggestions: true, // Enable link auto-suggestions in the editor.

  /**
   * Format Controls
   */
  enableTextJustification: false, // Enable or disable text justification controls in block toolbar.
  enableDropCap: true, // Enable the drop cap option for paragraphs.
  
  /**
   * Keyboard Shortcuts
   */
  keyboardShortcuts: {
    'core/block/duplicate': 'ctrl+d', // Duplicate block
    'core/block/delete': 'ctrl+del', // Delete block
  },

  /**
   * Copy-Paste Behavior
   */
  pasteBehavior: {
    mergeOnPaste: true, // Merges content when pasting into an existing block.
    removeStylesOnPaste: false, // Removes inline styles when pasting from external sources.
  },

  /**
   * Global Style Settings
   */
  enableGlobalStyles: true, // Enables global block styles for all blocks.
  enableGlobalTypography: true, // Controls whether typography settings apply globally.
  
  /**
   * Miscellaneous
   */
  supportsReusableBlocks: true, // Enables or disables reusable blocks.
  keepCaretInsideBlock: true, // Keeps the caret within the block when typing.
  dropCap: false, // Disable drop caps for paragraphs.
  richText: {
    canFormatBold: true, // Enable/disable bold formatting.
    canFormatItalic: true, // Enable/disable italic formatting.
  },
};

const WritePage = () => {
  const [editorState, setEditorState] = useState([]); // State for managing editor content
  // const navigate = useNavigate();
  // Register default core blocks like paragraphs, headings, images, etc.
  registerCoreBlocks();

  // Function to save content (can be integrated into your backend API)
  const saveContent = () => {
    const content = serialize(editorState); // Serialize the editor content
    console.log("Serialized Content:", content); 
    // Here, send `content` to your backend or save it in your state.
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header Section */}
      {/* <header className="bg-gray-800 text-white flex items-center justify-between p-4">
      <div onClick={() => navigate("/")} className="cursor-pointer" // Change cursor to pointer for better UX 
          role="button" // Adds accessibility
          tabIndex={0} // Makes it focusable
      >
        <img
          src="https://img.logoipsum.com/250.svg" // Replace with your logo URL or a placeholder image URL
          alt="Logo"
          className="h-10 w-auto" // Adjust height as needed
        />
      </div>
      </header> */}

      {/* Main Content Area */}
      <main className="flex-grow p-5">
        <SlotFillProvider>
          <div className="p-5 border border-gray-300 rounded-md shadow-lg">
            <BlockEditorProvider
              value={editorState}
              onInput={setEditorState} // Update editor state on input
              onChange={setEditorState} // Update editor state on change
              settings={editorSettings} // Editor settings
            >
              <BlockTools>
                <WritingFlow>
                  <ObserveTyping>
                    <BlockList /> {/* Displays the list of blocks */}
                  </ObserveTyping>
                </WritingFlow>
              </BlockTools>
            </BlockEditorProvider>

            {/* Save button */}
            <button
              onClick={saveContent}
              className="mt-5 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
            >
              Save Content
            </button>
          </div>
        </SlotFillProvider>
      </main>
    </div>
  );
};

export default WritePage;