import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
  // const navigate = useNavigate();
  // State to manage profile data
  const [profile, setProfile] = useState({
    name: "John Doe",
    email: "john.doe@example.com",
    bio: "A passionate content creator and educator.",
    profilePicture: null,
    followers: 120,
    following: 180
  });

  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState('published'); // Tabs for Blogs

  // Dummy data for blogs
  const publishedBlogs = [
    { title: "How to Design a User Profile Page", date: "2 days ago" },
    { title: "Understanding React State Management", date: "1 week ago" },
  ];

  const draftBlogs = [
    { title: "Introduction to JavaScript Closures", date: "3 days ago" },
    { title: "Building Reusable React Components", date: "1 month ago" },
  ];

  // Handle input change for text fields
  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value
    });
  };

  // Handle profile picture upload
  const handleProfilePicture = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfile({
        ...profile,
        profilePicture: URL.createObjectURL(file)
      });
    }
  };

  // Toggle Edit Mode
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Profile Header */}
      <div className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center">
            {/* Placeholder for Logo
            <div
      onClick={() => navigate("/")}
      className="cursor-pointer" // Change cursor to pointer for better UX
      role="button" // Adds accessibility
      tabIndex={0} // Makes it focusable
    >
      <img
        src="https://img.logoipsum.com/250.svg"
        alt="logo home"
        className="w-64 h-auto" // Add Tailwind classes for width and height
      />
    </div> */}

            {/* Profile Picture */}
            <div className="w-24 h-24 rounded-full overflow-hidden ml-6 relative">
              <img
                src={profile.profilePicture || "https://randomuser.me/api/portraits/men/55.jpg"}
                alt="Profile"
                className="w-full h-full object-cover"
              />
              {isEditing && (
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleProfilePicture}
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              )}
            </div>

            <div className="ml-6">
              <h2 className="text-3xl font-bold">{profile.name}</h2>
              <p className="text-gray-600">{profile.bio}</p>

              {/* Followers/Following */}
              <div className="flex mt-2 space-x-4">
                <div>
                  <span className="text-gray-700 font-bold">{profile.followers}</span> Followers
                </div>
                <div>
                  <span className="text-gray-700 font-bold">{profile.following}</span> Following
                </div>
              </div>

              {/* Edit Profile Button */}
              <button
                onClick={toggleEdit}
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                {isEditing ? "Cancel Edit" : "Edit Profile"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Profile Info Section */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">Profile Info</h3>

            <div className="mb-4">
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={profile.name}
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none"
                disabled={!isEditing}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={profile.email}
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none"
                disabled={!isEditing}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Bio</label>
              <textarea
                name="bio"
                value={profile.bio}
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none"
                disabled={!isEditing}
              />
            </div>

            {isEditing && (
              <div className="text-right">
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  onClick={toggleEdit}
                >
                  Save Changes
                </button>
              </div>
            )}
          </div>

          {/* Tabs for Published and Draft Blogs */}
          <div className="md:col-span-2 bg-white p-6 rounded-lg shadow-md">
            <div className="flex space-x-4 mb-4">
              <button
                className={`px-4 py-2 rounded ${activeTab === 'published' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('published')}
              >
                Published Blogs
              </button>
              <button
                className={`px-4 py-2 rounded ${activeTab === 'drafts' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('drafts')}
              >
                Draft Blogs
              </button>
            </div>

            {/* Display blogs based on active tab */}
            {activeTab === 'published' && (
              <ul className="space-y-4">
                {publishedBlogs.map((blog, index) => (
                  <li key={index} className="flex items-center justify-between">
                    <span>{blog.title}</span>
                    <span className="text-gray-500 text-sm">{blog.date}</span>
                  </li>
                ))}
              </ul>
            )}

            {activeTab === 'drafts' && (
              <ul className="space-y-4">
                {draftBlogs.map((blog, index) => (
                  <li key={index} className="flex items-center justify-between">
                    <span>{blog.title}</span>
                    <span className="text-gray-500 text-sm">{blog.date}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
