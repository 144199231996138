import React, { useState, useEffect } from 'react';

// Example list of users with ui-avatars.com links and descriptions
const mockUsers = [
  { id: 1, name: 'John Doe', avatar: 'https://randomuser.me/api/portraits/men/25.jpg', description: 'Web Developer', isFollowing: false },
  { id: 2, name: 'Jane Smith', avatar: 'https://randomuser.me/api/portraits/women/25.jpg', description: 'Graphic Designer', isFollowing: false },
  { id: 3, name: 'Alice Johnson', avatar: 'https://randomuser.me/api/portraits/men/55.jpg', description: 'Content Creator', isFollowing: false }
];

const WhoToFollow = () => {
  const [users, setUsers] = useState([]);

  // Simulate fetching data
  useEffect(() => {
    setUsers(mockUsers);
  }, []);

  // Handle follow/unfollow button click
  const handleFollow = (userId) => {
    setUsers(users.map(user => 
      user.id === userId ? { ...user, isFollowing: !user.isFollowing } : user
    ));
  };

  return (
    <div className="bg-white p-5 rounded-lg shadow-lg max-w-sm">
      <h3 className="text-lg font-bold mb-4">Blenvers to Follow</h3>
      <ul>
        {users.map(user => (
          <li key={user.id} className="flex justify-between items-center mb-4 hover:bg-indigo-50 p-2 rounded">
            {/* User Info Section */}
            <div className="flex items-center">
              <img 
                src={user.avatar} 
                alt={user.name} 
                className="w-12 h-12 rounded-full mr-3"
              />
              <div>
                <p className="font-medium">{user.name}</p>
                <p className="text-sm text-gray-500">{user.description}</p>
              </div>
            </div>
            {/* Follow/Unfollow Button */}
            <button 
              onClick={() => handleFollow(user.id)}
              className={`px-4 py-1 text-sm font-semibold rounded-full ${
                user.isFollowing 
                  ? 'bg-gray-300 text-gray-700' 
                  : 'bg-blue-500 text-white'
              } hover:opacity-90`}
            >
              {user.isFollowing ? 'Unfollow' : 'Follow'}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WhoToFollow;
