import './input.css';
import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import HomePage from './pages/HomePage';
import WritePage from './pages/WritePage';
import UserProfile from './pages/UserProfile';
import ExplorePage from './pages/ExplorePage';
import SettingsPage from './pages/SettingsPage';
import TrendingBlogs from './pages/TrendingBlogs';
import LoginPage from './pages/LoginPage';
import ChatSidebar from "./components/ChatSidebar";

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation(); // Move useLocation inside BrowserRouter

  // Determine whether to apply the margins based on the current path
  const isLoginPage = location.pathname === '/';

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">

      {/* Main Content Area */}
      <div className={`flex-1 ${!isLoginPage ? 'ml-64 mr-64' : ''}`}>
        <div className="h-full overflow-y-auto"> {/* Apply scrolling to the whole content */}
          <Routes>
            <Route path="/WritePage" element={<WritePage />} />
            <Route path="/SettingsPage" element={<SettingsPage />} />
            <Route path="/ExplorePage" element={<ExplorePage />} />
            <Route path="/HomePage" element={<HomePage />} />
            <Route path="/UserProfile" element={<UserProfile />} />
            <Route path="/TrendingBlogs" element={<TrendingBlogs />} />
            <Route path="/" element={<LoginPage />} />
          </Routes>
        </div>
      </div>

      {/* Chat Sidebar (Right) */}
      {!isLoginPage && (
        <div className="w-64 h-full fixed right-0 top-0">
          <ChatSidebar />
        </div>
      )}
    </div>
  );
}

export default App;
