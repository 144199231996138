import React, { useState } from "react";
import { Share, ThumbsUpIcon }  from  "lucide-react";

const FeedsCard = ({ question }) => {
  const [likes, setLikes] = useState(0);

  const handleLike = () => {
    setLikes(likes + 1);
  };

  const handleShare = () => {
    const shareUrl = window.location.href; // You can customize this
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert("Link copied to clipboard!");
    });
  };

  return (
    <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg p-6 my-4">
         {/* Author Section */}
      <div className="flex items-center">
        {/* Profile Picture */}
        <img
          src={question.authorProfilePic}
          alt={question.author}
          className="w-12 h-12 rounded-full object-cover mr-4"
        />
        {/* Author Name and Credentials */}
        <div>
          <h4 className="text-lg font-semibold text-gray-900">{question.author}</h4>
          <p className="text-sm text-gray-500">{question.authorCredentials}</p>
        </div>
      </div>
      {/* Question Title */}
      <h2 className="text-2xl font-bold text-gray-900">{question.title}</h2>

      {/* Question Description */}
      <p className="text-gray-700 mt-2">{question.description}</p>
      {/* Summary Section */}
      <div className="mt-4">
        <p className="text-gray-600 mt-2">{question.summary}</p>
      </div>
      {/* Video Section */}
      <div className="mt-4">
        <iframe
          className="w-full h-64 rounded-md"
          src={question.videoUrl}
          title="Question video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      {/* Category Tags */}
      <div className="mt-2 flex space-x-2">
        {question.categories.map((category, index) => (
          <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-md text-sm">
            {category}
          </span>
        ))}
      </div>
      {/* Question Meta (Author and Timestamp) */}
      <div className="mt-4 flex justify-between items-center text-gray-500 text-sm">
        <span>{question.timestamp}</span>
      </div>



 <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <ThumbsUpIcon 
            className="w-6 h-6 text-gray-700 cursor-pointer"
            onClick={handleLike}
          />
          <span className="text-gray-700 ml-2">{likes} {likes === 1 ? "Like" : "Likes"}</span>
        </div>
        <Share
          className="w-6 h-6 text-gray-700 cursor-pointer"
          onClick={handleShare}
        />
      </div>
    </div>
  );
};

const Feeds = () => {
  // Array of questions from different fields
  const questions = [
    {
        id: 1,
        title: "The Rise of AI: How It Will Change Our Future",
        summary: "Artificial Intelligence is rapidly evolving and is poised to revolutionize industries. This blog explores the potential benefits and challenges of AI as it reshapes the world.",
        author: "John Doe",
        authorProfilePic: "https://randomuser.me/api/portraits/men/32.jpg",
        authorCredentials: "AI Researcher",
        timestamp: "2 hours ago",
        videoUrl: "https://www.youtube.com/embed/3P1fnBq1S04",
        categories: ["AI", "Technology", "Future"],
      },
      {
        id: 2,
        title: "The Importance of Regular Exercise",
        summary: "This blog discusses how regular physical activity improves mental and physical health. It offers tips for building a sustainable exercise routine.",
        author: "Jane Smith",
        authorProfilePic: "https://randomuser.me/api/portraits/women/44.jpg",
        authorCredentials: "Certified Fitness Trainer",
        timestamp: "5 hours ago",
        videoUrl: "https://www.youtube.com/embed/vm6iyqw_v2Q",
        categories: ["Health", "Fitness", "Lifestyle"],
      },
      {
        id: 3,
        title: "Online Education: The New Normal",
        summary: "Online education is here to stay, but it comes with its own set of challenges. This blog examines the pros and cons of remote learning and what the future holds for education.",
        author: "Emily Johnson",
        authorProfilePic: "https://randomuser.me/api/portraits/women/68.jpg",
        authorCredentials: "Education Specialist",
        timestamp: "1 day ago",
        videoUrl: "",
        categories: ["Education", "Technology", "Online Learning"],
      },
      {
        id: 4,
        title: "Cryptocurrency: Disrupting the Financial Industry",
        summary: "Cryptocurrency is reshaping the financial landscape. This blog delves into how Bitcoin and other cryptocurrencies are disrupting traditional finance.",
        author: "Michael Lee",
        authorProfilePic: "https://randomuser.me/api/portraits/men/25.jpg",
        authorCredentials: "Blockchain Developer",
        timestamp: "3 days ago",
        videoUrl: "https://www.youtube.com/embed/gGHJ6s5hQ6U",
        categories: ["Finance", "Cryptocurrency", "Blockchain"],
      },
      {
        id: 5,
        title: "Streaming Services: The Future of Entertainment",
        summary: "Streaming platforms like Netflix and Spotify are transforming the entertainment industry. This blog looks at how these services have altered the way we consume content.",
        author: "Sarah Connor",
        authorProfilePic: "https://randomuser.me/api/portraits/women/30.jpg",
        authorCredentials: "Media Analyst",
        timestamp: "1 week ago",
        videoUrl: "https://www.youtube.com/embed/tEgaepR4X0o",
        categories: ["Entertainment", "Media", "Streaming"],
      },
    ];

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto px-4 py-8">
        {/* <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Question Feed</h1> */}
        {/* Render each question card */}
        {questions.map((question) => (
          <FeedsCard key={question.id} question={question} />
        ))}
      </div>
    </div>
  );
};

export default Feeds;
