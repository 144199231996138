// src/pages/LoginPage.js
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import { Box } from '@mui/material'; // Ensure Material UI's Box is imported
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './fb.css';


const FbLoginButton = () => {
  const navigate = useNavigate();

  const handleFacebookResponse = (response) => {
    const accessToken = response.accessToken;

    // Send access token to PHP backend using Axios
    axios.post('https://blenve.com/api/facebook-login.php', { accessToken })
      .then((res) => {
        if (res.data.success) {
          // console.log('Response from backend:', res.data); // Print the full response from the backend
          // console.log('Facebook user authenticated:', res.data);
          // Handle successful authentication (e.g., store user session, redirect)
          navigate('/HomePage');
        } else {
          // console.log('Response from backend:', res.data); // Print the full response from the backend
          console.error('Facebook authentication failed');
        }
      })
      .catch((error) => {
        console.error('Facebook authentication error:', error);
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FacebookLogin
        appId="2679978505515692" // Replace with your actual Facebook app ID
        autoLoad={false}
        fields="name,picture"
        callback={handleFacebookResponse}
        icon="fa fa-facebook"
        textButton="Facebook"
        cssClass="my-facebook-button" // Your custom CSS class for the button
        style={{
          width: '40%', // Make the button fill the container width
        }}
      />
    </Box>
  );
};

export default FbLoginButton;




