import React, { useState } from 'react';
import { ChevronUp, ChevronDown } from "lucide-react"; // Importing arrow icons

const TrendingBlogs = () => {
  const initialBlogs = [
    { id: 1, title: "React vs Vue: Which is Better?", views: 2450, date: "01/10" },
    { id: 2, title: "Understanding JavaScript Closures", views: 1800, date: "30/09" },
    { id: 3, title: "10 Tips to Write Clean Code", views: 3200, date: "28/09" },
    { id: 4, title: "How to Use Tailwind CSS with React", views: 1500, date: "27/09" },
  ];

  const moreBlogs = [
    { id: 5, title: "The Rise of AI in Software Development", views: 4100, date: "25/09" },
    { id: 6, title: "A Guide to Functional Programming in JavaScript", views: 2200, date: "24/09" },
    { id: 7, title: "Why TypeScript is the Future of JavaScript", views: 3300, date: "23/09" },
  ];

  const [blogs, setBlogs] = useState(initialBlogs);
  const [expanded, setExpanded] = useState(false);

  const handleShowMore = () => {
    if (expanded) {
      setBlogs(initialBlogs);
    } else {
      setBlogs([...initialBlogs, ...moreBlogs]);
    }
    setExpanded(!expanded);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-4 mb-6"> {/* Card-like container */}
      <h2 className="text-lg font-semibold mb-4">Trending Blogs</h2> {/* Card title */}
      <ul>
        {blogs.map((blog) => (
          <li key={blog.id} className="mb-3 pb-2 border-b">
            <h3 className="text-base font-medium text-gray-800">{blog.title}</h3> {/* Blog title */}
            <div className="text-sm text-gray-500">
              <span>{blog.views} views</span> | <span>{blog.date}</span> {/* Blog metadata */}
            </div>
          </li>
        ))}
      </ul>

      <button 
        onClick={handleShowMore} 
        className="flex items-center text-blue-500 mt-4 text-sm font-medium">
        {expanded ? <ChevronUp className="mr-1" /> : <ChevronDown className="mr-1" />}
        {expanded ? 'Show Less' : 'Show More'}
      </button>
    </div>
  );
};

export default TrendingBlogs;
