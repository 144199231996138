
import WhoToFollow from "./../components/WhoToFollow";
import Feeds from "./../components/Feeds";
import TrendingBlogs from "./../components/TrendingBlogs"
import { useLocation } from 'react-router-dom';
import Sidebar from "./../components/Sidebar"; 

const HomePage = () => {
  const location = useLocation();
  const user = location.state; // Access the passed user data
  // console.log('Settings saved:', { name: user?.name, email: user?.email, profilePicture: user?.profile_picture });
    return(
      <div className="flex flex-1 overflow-y-auto">
      {/* Sidebar (Left) */}
        <div className="w-64 h-full fixed left-0 top-0">
        <Sidebar
        name={user?.name}
        email={user?.email}
        profilepicture={user?.profile_picture}
       />
      </div>

      {/* Feeds */}
      <div className="flex-1 p-4">
        <Feeds />
      </div>
  
      {/* Right Section - Trending Blogs and Who to Follow */}
      <div className="flex flex-col w-1/4 p-4">
        <div className="sticky top-0">
          {/* Trending Blogs */}
          <div className="mb-4">
            <TrendingBlogs />
          </div>
          {/* Who to Follow */}
          <WhoToFollow />
        </div>
      </div>
    </div>
    );
  };
  
  export default HomePage;