import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation'; // If you are using navigation module
import 'swiper/css/pagination'; // If you are using pagination module
import { Navigation, Pagination } from 'swiper/modules'; // Import Swiper modules
import { useNavigate } from 'react-router-dom';

// Sample data for trending blogs with categories
const trendingBlogs = {

  "Technology": [
    {
      id: 1,
      title: 'Understanding React Hooks: A Comprehensive Guide',
      author: 'John Doe',
      date: 'October 1, 2024',
      excerpt: 'Explore the world of React Hooks and learn how to simplify your component logic...',
      image: 'https://picsum.photos/300/300?random=1',
    },
    {
      id: 2,
      title: 'The Future of Web Development: Trends to Watch',
      author: 'Alice Johnson',
      date: 'September 25, 2024',
      excerpt: 'Stay ahead of the curve by understanding the upcoming trends in web development...',
      image: 'https://picsum.photos/300/300?random=2',
    },
    {
      id: 3,
      title: 'Understanding React Hooks: A Comprehensive Guide',
      author: 'John Doe',
      date: 'October 1, 2024',
      excerpt: 'Explore the world of React Hooks and learn how to simplify your component logic...',
      image: 'https://picsum.photos/300/300?random=3',
    },
    {
      id: 4,
      title: 'The Future of Web Development: Trends to Watch',
      author: 'Alice Johnson',
      date: 'September 25, 2024',
      excerpt: 'Stay ahead of the curve by understanding the upcoming trends in web development...',
      image: 'https://picsum.photos/300/300?random=4',
    },
  ],
  "Health & Wellness": [
    {
      id: 3,
      title: '10 Simple Exercises for a Healthy Lifestyle',
      author: 'Jane Smith',
      date: 'September 30, 2024',
      excerpt: 'Improve your well-being with these simple exercises you can do anywhere...',
      image: 'https://picsum.photos/300/300?random=5',
    },
    {
      id: 4,
      title: 'Meditation for Beginners: A Step-by-Step Guide',
      author: 'Mark Evans',
      date: 'September 28, 2024',
      excerpt: 'Learn how to incorporate meditation into your daily routine with ease...',
      image: 'https://picsum.photos/300/300?random=6',
    },
    {
      id: 3,
      title: '10 Simple Exercises for a Healthy Lifestyle',
      author: 'Jane Smith',
      date: 'September 30, 2024',
      excerpt: 'Improve your well-being with these simple exercises you can do anywhere...',
      image: 'https://picsum.photos/300/300?random=7',
    },
    {
      id: 4,
      title: 'Meditation for Beginners: A Step-by-Step Guide',
      author: 'Mark Evans',
      date: 'September 28, 2024',
      excerpt: 'Learn how to incorporate meditation into your daily routine with ease...',
      image: 'https://picsum.photos/300/300?random=8',
    },
  ],
  "Finance": [
    {
      id: 5,
      title: 'How to Start Investing: A Beginner’s Guide',
      author: 'Linda Brown',
      date: 'September 22, 2024',
      excerpt: 'Here’s everything you need to know to start investing and growing your wealth...',
      image: 'https://picsum.photos/300/300?random=9',
    },
    {
      id: 6,
      title: 'The Importance of Financial Planning for the Future',
      author: 'David White',
      date: 'September 20, 2024',
      excerpt: 'Financial planning is crucial for a secure future. Learn how to manage your finances...',
      image: 'https://picsum.photos/300/300?random=10',
    },
    {
      id: 3,
      title: '10 Simple Exercises for a Healthy Lifestyle',
      author: 'Jane Smith',
      date: 'September 30, 2024',
      excerpt: 'Improve your well-being with these simple exercises you can do anywhere...',
      image: 'https://picsum.photos/300/300?random=11',
    },
    {
      id: 4,
      title: 'Meditation for Beginners: A Step-by-Step Guide',
      author: 'Mark Evans',
      date: 'September 28, 2024',
      excerpt: 'Learn how to incorporate meditation into your daily routine with ease...',
      image: 'https://picsum.photos/300/300?random=12',
    },
  ]
};


const TrendingBlogs = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header Section */}
      <header className="w-full  p-5 flex ">
      <div
      onClick={() => navigate("/")}
      className="cursor-pointer" // Change cursor to pointer for better UX
      role="button" // Adds accessibility
      tabIndex={0} // Makes it focusable
    >
      <img
        src="https://img.logoipsum.com/250.svg"
        alt="logo home"
        className="w-64 h-auto" // Add Tailwind classes for width and height
      />
      </div>
      </header>

      <div className="flex-grow p-6 max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-gray-800 mb-6">Trending Blogs</h2>

        {/* Loop over each category */}
        {Object.keys(trendingBlogs).map((category, index) => (
          <div key={index} className="mb-12">
            <h3 className="text-2xl font-semibold text-gray-700 mb-6">{category}</h3>

            {/* Swiper for each category */}
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              breakpoints={{
                640: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 3 },
              }}
              modules={[Navigation, Pagination]}
              navigation={{
                nextEl: `.swiper-button-next-${index}`,
                prevEl: `.swiper-button-prev-${index}`,
              }}
              pagination={{ clickable: true }}
              className="w-full"
            >
              {trendingBlogs[category].map((blog) => (
                <SwiperSlide key={blog.id}>
                  <div className="relative border border-gray-200 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 blog-card">
                    <img
                      src={blog.image}
                      alt={blog.title}
                      className="w-full h-48 object-cover transition-transform duration-300 transform hover:scale-105"
                    />
                    <div className="p-4 bg-white">
                      <h3 className="text-xl font-semibold text-gray-900 hover:text-blue-600 transition-colors duration-300 mb-2">
                        {blog.title}
                      </h3>
                      <p className="text-gray-600 text-sm mb-2">
                        <span className="font-medium">{blog.author}</span> • {blog.date}
                      </p>
                      <p className="text-gray-700 text-sm mb-4">{blog.excerpt}</p>
                      <a
                        href={`/blog/${blog.id}`}
                        className="inline-block bg-blue-500 text-white text-sm px-4 py-2 rounded hover:bg-blue-600 transition"
                      >
                        Read more
                      </a>
                    </div>
                    {/* Badge */}
                    <div className="absolute top-0 left-0 bg-red-500 text-white text-xs font-semibold px-3 py-1 rounded-br-lg">
                      Trending
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingBlogs;
